import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getTour } from '../helpers/getTour';
import { CalificationSection } from './CalificationSection';
// import { HeaderComponent } from './HeaderComponent';
import { useForm } from '@formspree/react';
import { ThanksReservationPage } from '../Pages/ThanksReservationPage';
import { TikalForm } from './TikalForm';
import { ContactComponent } from './ContactComponent';
import { ReservarComponent } from './ReservarComponent';
import { PriceTableComponent } from './PriceTableComponent';
import Swal from 'sweetalert2';
import { FooterComponent } from './FooterComponent';
import { IncludeComponent } from './IncludeComponent';
// import { ReservationForm } from './ReservationForm';
// import { useForm } from '../hooks/useForm';

export const TourPage = () => {

  const { tourId } = useParams();
  const navigate = useNavigate();
  const [state, handleSubmit] =  useForm("xwkdyanr");
  const tourInformation = getTour( tourId );
  // todo: useForm
  // const [ values, handleInputChange ] = useForm();
  const [display, setDisplay] = useState('none');
  const [reserve, setReserve] = useState('Reservar');
  const [passengers, setPassengers] = useState(0);
  const [fixedPrice, setFixedPrice] = useState(0);
  const [validateInput, setValidateInput] = useState([]);
  const [ dateErrMsg, setDateErrMsg ] = useState('');
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [ reservationCode, setReservationCode ] = useState(null);
  const [ reservationData, setReservationData ] = useState({
    name: '',
    schedule: '',
    date: null,
    passengers: 0,
    departure: '',
    tour: '',
    included: [],
    notIncluded: [],
    price: 0,
  });

  // Form References
  const nameDesktop = useRef(null);
  const phoneDesktop = useRef(null);
  const dateDesktop = useRef(null);
  const nameMobile = useRef(null);
  const phoneMobile = useRef(null);
  const dateMobile = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ tourId ])
  

  const {
		title,
		stars,
		amountReviews,
		photos,
		price,
    // minimoPersonas,
    preciosPorPersona,
		time,
		description,
    schedules,
    highlights,
		included,
		notIncluded,
    whatToExpect: departurePoint,
		// additionalInformation,
		cancellationPolicy,
		FAQ,
		travelerPhotos,
		reviews,
  } = tourInformation;

  const [img, setImg] = useState(photos[0]);
  const nonSelectedClass = "img-non-selected gallery-img";

  const handleClick = ( e ) => {
    const image = e.target;
    const arrImgs = document.querySelectorAll('.gallery-img');
    arrImgs.forEach( i => {
      i.className = `${nonSelectedClass}`;
    });
    image.className = 'gallery-img';
    setImg( image.src );
  };

  const handleChance = ( e ) => {
    const value = e.target.value;
    return value;
  };

  const handleSelectSchedule = e => {
    const selectedSchedule = e.target.value;
    setCurrentSchedule( selectedSchedule );
  };

  const handleShow = ( e ) => {
    e.preventDefault();
    if (display === 'none'){
      setReserve('Ocultar');
      setDisplay('block');
      window.scrollTo(0, 0);
      setTimeout( () => {
        nameMobile.current.focus();
      }, 250);
    } else {
      setDisplay('none');
      setReserve('Reservar');
    }
  };

  const handleValidate = (e) => {
    const input = e.target;
    
    switch (input.name) {
      case 'Nombre':
        if (input.value.trim().length < 3) {
          setValidateInput([
            'is-invalid',
          ]);
          input.focus();
        } else {
          setValidateInput([
            'is-valid',
          ]);
        }
        break;
      case 'Telefono':
        if (!/^(\+\d{1,4})?[\s.-]?\(?\d{1,}\)?[\s.-]?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}$/.test(input.value)) {
          setValidateInput([
            ...validateInput,
            'is-invalid',
          ]);
          input.focus();
        } else {
          setValidateInput([
            'is-valid',
            'is-valid',
          ]);
        }
        break;
      case 'Fecha':
          const today = new Date();
          const reservationDate = new Date( input.value );
          if (reservationDate < today && reservationDate.getDate() + 1 === today.getDate() && today.getHours() < 18){
            setValidateInput([
              'is-valid',
              'is-valid',
              'is-valid',
            ]);
          } else if (reservationDate < today){
            setDateErrMsg('Ingrese una fecha válida');
            setValidateInput([
              'is-valid',
              'is-valid',
              'is-invalid',
            ]);
            input.focus();
          } else {
            setValidateInput([
              'is-valid',
              'is-valid',
              'is-valid',
            ]);
          }
          break;
      default:
        break;
    }
  };

  const validateForm = ev => {
    const name = window.innerWidth > 1170 ? nameDesktop.current.value : nameMobile.current.value;
    const phone = window.innerWidth > 1170 ? phoneDesktop.current.value : phoneMobile.current.value;
    const date = new Date(window.innerWidth > 1170 ? dateDesktop.current.value : dateMobile.current.value);
    date.setDate(date.getDate() + 1);

    // FIELDS VALIDATION
    if (name.trim().length < 3 ||
        !/^(\+\d{1,4})?[\s.-]?\(?\d{1,}\)?[\s.-]?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}$/.test(phone) ||
        date < new Date() ||
        date.toString() === 'Invalid Date' ||
        passengers === 0) {
          if (name.trim().length < 3 && !/^(\+\d{1,4})?[\s.-]?\(?\d{1,}\)?[\s.-]?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}$/.test(phone) && (date < new Date() || date.toString() === 'Invalid Date')){
            setValidateInput([
              'is-invalid',
              'is-invalid',
              'is-invalid',
            ]);
            Swal.fire({
              title: "Reserva fallida",
              text: "Llenar todos lo campos",
              icon: "error",
              confirmButtonColor: "#336699",
            });
          } else if (name.trim().length < 3 && !/^(\+\d{1,4})?[\s.-]?\(?\d{1,}\)?[\s.-]?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}$/.test(phone)){
            console.log('here');
            setValidateInput([
              'is-invalid',
              'is-invalid',
              'is-valid',
            ]);
            Swal.fire({
              title: "Reserva fallida",
              text: "Llenar su nombre y teléfono",
              icon: "error",
              confirmButtonColor: "#336699",
            });
          } else if (name.trim().length < 3 && date < new Date()){
            setValidateInput([
              'is-invalid',
              'is-valid',
              'is-invalid',
            ]);
            Swal.fire({
              title: "Reserva fallida",
              text: "Llenar su nombre e ingrese una fecha válida",
              icon: "error",
              confirmButtonColor: "#336699",
            });
          } else if (!/^(\+\d{1,4})?[\s.-]?\(?\d{1,}\)?[\s.-]?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}$/.test(phone) && date < new Date()){
            setValidateInput([
              'is-valid',
              'is-invalid',
              'is-invalid',
            ]);
            Swal.fire({
              title: "Reserva fallida",
              text: "Llenar su teléfono e ingrese una fecha válida",
              icon: "error",
              confirmButtonColor: "#336699",
            });
          } else if (name.trim().length < 3){
            // name
          } else if (!/^(\+\d{1,4})?[\s.-]?\(?\d{1,}\)?[\s.-]?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}$/.test(phone)){
            // phone
          } else if (date < new Date() || date.toString() === 'Invalid Date'){
            // date
          } else if (passengers === 0){
            // passengers
          }
          return false;
    } else {
      // --------SUBMIT FORM--------
      setTimeout( () => {
        Swal.fire({
          title: "Reserva exitosa",
          text: "Gracias por reservar",
          icon: "success",
          confirmButtonColor: "#336699",
        });
        setValidateInput(['', '', '',]);
      }, 50);
      return true;
    }
  };

  const handleCalculatePrice = e => {
    const adults = Number.parseInt(document.getElementById( window.innerWidth >= 1170 ? 'adults-desktop' : 'adults-mobile').value);
    const kids = Number.parseInt(document.getElementById( window.innerWidth >= 1170 ? 'kids-desktop' : 'kids-mobile').value);
    // setPassengers( passengers + Number.parseInt(e.target.value) );
    const pasajeros = adults + kids;
    setPassengers( pasajeros );

    if (pasajeros === 0){
      setFixedPrice(0);
    } else if (pasajeros >= 5){
      setFixedPrice( pasajeros * preciosPorPersona[4] );
    } else{
      setFixedPrice( pasajeros * preciosPorPersona[ pasajeros - 1] );
    }

    // if (passengers === 0) {
    //   setFixedPrice(0);
    // } else if (pasajeros === 1) {
    //   setFixedPrice( preciosPorPersona[0] );
    // } else if (pasajeros === 2) {
    //   setFixedPrice( preciosPorPersona[1] );
    // } else if (pasajeros === 3) {
    //   setFixedPrice( preciosPorPersona[2] );
    // } else if (pasajeros === 4) {
    //   setFixedPrice( preciosPorPersona[3] );
    // } else if (pasajeros === 5) {
    //   setFixedPrice( preciosPorPersona[4] );
    // } else {
    //   setFixedPrice( preciosPorPersona[4] );
    // }
  };

  const handleClickSubmit = e => {
    e.preventDefault();
    generateCode();
    return validateForm(e);
  };

  const generateCode = () => {
    let code = 'G';
    const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,];
    const specialCharacters = ['!', '@', '#', '$', '%', '&'];
    code += nums[Math.floor(Math.random() * nums.length)];
    code += nums[Math.floor(Math.random() * nums.length)];
    code += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
    code += '24';
    code += nums[Math.floor(Math.random() * nums.length)];
    code += nums[Math.floor(Math.random() * nums.length)];
    code += nums[Math.floor(Math.random() * nums.length)];
    code += nums[Math.floor(Math.random() * nums.length)];
    const name = window.innerWidth > 1170 ? nameDesktop.current.value : nameMobile.current.value;
    const date = new Date(window.innerWidth > 1170 ? dateDesktop.current.value : dateMobile.current.value);
    // toDo: Fix the data submission to create the document
    setReservationData({
      name,
      schedule: currentSchedule,
      date,
      passengers,
      departure: tourId.split('-')[0],
      tour: tourId.split('-')[1],
      included,
      notIncluded,
      price: fixedPrice,
    });
    
    setReservationCode( code );
  };

  const containsTikal = tourId.includes('tikal');
  let availabilityMessage = '';
  let showTransport = '';
  let transportAditional = '';
  let isTikal = '';
  if (tourId === 'flores-tikal' || tourId === 'flores-amanecer-tikal') {
    availabilityMessage = 'Sin mínimos';
    showTransport = '';
    transportAditional = '';
    isTikal = '';
  } else if (containsTikal) {
    availabilityMessage = 'Sin mínimos';
    showTransport = 'hide';
    transportAditional = 'El tour de Tikal inicia y finaliza en el Aeropuerto Internacional "La Aurora". El traslado hacia y desde el aeropuerto no está incluido, sin embargo se puede incluir por un suplemento.';
    isTikal = 'is-tikal';
  } else {
    availabilityMessage = 'Sin mínimos';
    showTransport = '';
    transportAditional = '';
    isTikal = '';
  }

  if (state.succeeded) {
    return <ThanksReservationPage tourInformation={{ ...tourInformation, schedules: currentSchedule, }} reservationCode={ reservationCode } reservationData={ reservationData } /> // toDo: add ' reservationData={ reservationData }'
  }

  return (
    <>
        <div className='tour-container'>
          <div className="row tour-title">
            {/* <h1>{ window.screen.width }</h1> */}
            {/* <CalificationSection stars={stars} amountReviews={amountReviews} /> */}
          </div>
          <div className="row">
            <div className="standard-version tour-top">
              <div className="col-2 tour-img-gallery">
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ `${title}-toursguate.com` } className="gallery-img" onClick={ handleClick } />
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ title } className="img-non-selected gallery-img" onClick={ handleClick } />
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ title } className="img-non-selected gallery-img" onClick={ handleClick } />
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ title } className="img-non-selected gallery-img" onClick={ handleClick } />
              </div>
              <div className="col-7 tour-img-main">
                <img src={ img } alt={ title } className="tour-main-image" />
              </div>
              <div className="col-3">
                <div className="tour-price-container">
                  <div className="bold-price"><p>Desde <b>{ price.toFixed(2) }US$</b>por persona</p></div>
                  {/* <div className={minimoPersonas > 1 ? `min-pax ${isTikal}` : `min-pax-non-important ${isTikal}`}>{ minimoPersonas > 1 ? `Minimo de ${minimoPersonas} personas Suplemento si viaja solo` : 'Sin mínimos' }</div> */}
                  <div className={`min-pax ${isTikal}`}>Sujeto de disponibilidad</div>
                  <div className="form-control btn-warning">
                    <b>{ availabilityMessage }</b>
                  </div>
                  {/* <ReservationForm title={title} price={price} tourId={tourId} /> */}
                  <div className="form-reservation">
                    <form onSubmit={ evt => {
                      if( handleClickSubmit(evt) ){
                        handleSubmit(evt);
                      } else{
                        state.succeeded = false;
                      }
                    } } className='needs-validation' noValidate>
                      <label htmlFor="name-desktop">Nombre:</label>
                      <input className={`form-control ${validateInput[0]}`} type="text" name='Nombre' id='name-desktop' onBlur={ handleValidate } ref={ nameDesktop } placeholder='Nombre Apellido' required={true} autoComplete="off" />
                      <label htmlFor="Telefono">Teléfono:</label>
                      <input className={`form-control ${validateInput[1]}`} type="phone" name='Telefono' onBlur={ handleValidate } ref={ phoneDesktop } placeholder='+1 (800) 123 4567' required={true} autoComplete="off" />
                      <label htmlFor="Fecha">Fecha:</label>
                      <input className={`form-control ${validateInput[2]}`} type="date" name='Fecha' onBlur={ handleValidate } ref={ dateDesktop } required={true}/><div className="invalid-feedback">{ dateErrMsg }</div>
                      <label htmlFor="schedule-desktop">Selecione un horario: </label>
                      <select id="schedule-desktop" name='Horario' className='form-control' onChange={ handleSelectSchedule } onBlur={ handleCalculatePrice } defaultValue={0} >
                        <option value=""></option>
                        {
                          schedules.map( (element, i) => {
                            return (
                              <option key={`shcedule-${i}`} value={ element }>{ element }</option>
                            )
                          })
                        }
                      </select>
                      <div className="passengers-amount">
                        <label htmlFor="Adultos">Adultos: </label>
                        <select id="adults-desktop" name='Adultos' onChange={ handleChance } onBlur={ handleCalculatePrice } defaultValue={0} >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        <label htmlFor="kids-desktop">Niños (menores a 10ã): </label>
                        <select id="kids-desktop" name='Kids' onChange={ handleChance } onBlur={ handleCalculatePrice } defaultValue={0} >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                      <input type="text" value={title} name='Tour' style={{ display:'none' }} onChange={ handleChance } />
                      <input type="text" value={fixedPrice} name='Precio' style={{ display:'none' }} onChange={ handleChance } />
                      <input type="text" value={tourId.split('-')[0]} name='Origen' style={{ display:'none' }} onChange={ handleChance } />
                      <input type="number" value={ passengers } name='Pax' style={{ display:'none' }} onChange={ handleChance } />
                      <input type="text" value={ reservationCode } name='Reservation' style={{ display:'none' }} onChange={ handleChance } id="Reservation-Desktop" />
                      <input type="submit" value="Reservar" className="btn btn-info form-control" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="standard-version tikal-form">
              {
                containsTikal ? <TikalForm /> : ''
              }
            </div>
          </div>
          {/* --------------------------- PHONE VERSION ---------------------------------------------------- */}
          <div className="phone-version">
            <img src={ img } alt={ title } className="tour-main-image" />
            <div className="tour-img-gallery">
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ title } className={ "gallery-img" } onClick={ handleClick } />
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ title } className={ nonSelectedClass } onClick={ handleClick } />
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ title } className={ nonSelectedClass } onClick={ handleClick } />
                <img src={ "https://via.placeholder.com/1920x1080" } alt={ title } className={ nonSelectedClass } onClick={ handleClick } />
            </div>
            {/* <span><a href='../' conClick={ handleShow } id="showReservation" >{ display[1] }</a></span> */}
            <div className="form-reservation" style={{ display: display }}>
              <form onSubmit={ evt => {
                      if( handleClickSubmit(evt) ){
                        handleSubmit(evt);
                      } else{
                        state.succeeded = false;
                      }
                    } } className='needs-validation' noValidate>
                {/* <label>{ state.succeeded ? 'verdadeo' : 'falso' }</label> */}
                <label htmlFor="Nombre">Nombre:</label>
                <input className={`form-control ${validateInput[0]}`} type="text" name='Nombre' id='name-phone' onBlur={ handleValidate } ref={ nameMobile } required={true} placeholder='Nombre Apellido' autoComplete="off" />
                <label htmlFor="Telefono">Teléfono:</label>
                <input className={`form-control ${validateInput[1]}`} type="phone" name='Telefono' onBlur={ handleValidate } ref={ phoneMobile } placeholder='+1 (800) 123 45678' required={true} autoComplete="off" />
                <label htmlFor="Fecha">Fecha:</label>
                <input className={`form-control ${validateInput[2]}`} type="date" name='Fecha' onBlur={ handleValidate } ref={ dateMobile } required={true}/><div className="invalid-feedback">{ dateErrMsg }</div>
                <label htmlFor="schedule-mobile">Selecione un horario: </label>
                <select id="schedule-mobile" name='Horario' className='form-control' onChange={ handleSelectSchedule } onBlur={ handleCalculatePrice } defaultValue={0} >
                  <option value={ schedules.length === 1 ? schedules[0] : ""}></option>
                  {
                    schedules.map( (element, i) => {
                      return (
                        <option key={`shcedule-${i}`} value={ element }>{ element }</option>
                      )
                    })
                  }
                </select>
                <div className="passengers-amount">
                  <label htmlFor="adults-mobile">Adultos: </label>
                  <select id="adults-mobile" name='Adultos' onChange={ handleChance } onBlur={ handleCalculatePrice } defaultValue={0} >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <label htmlFor="kids-mobile">Niños (menores a 10ã): </label>
                  <select id="kids-mobile" name='Kids' onChange={ handleChance } onBlur={ handleCalculatePrice } defaultValue={0} >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <input type="text" value={title} name='Tour' style={{ display:'none' }} onChange={ handleChance } />
                <input type="text" value={fixedPrice} name='Precio' style={{ display:'none' }} onChange={ handleChance } />
                <input type="text" value={tourId} name='TourId' style={{ display:'none' }} onChange={ handleChance } />
                <input type="number" value={ passengers } name='Pax' style={{ display:'none' }} onChange={ handleChance } />
                <input type="text" value={ reservationCode } name='Reservation' style={{ display:'none' }} onChange={ handleChance } id="Reservation-Mobile" />
                <input type='submit' value='Reservar' className="btn btn-info form-control" />
              </form>
            </div>
            <div className="row tour-title">
              {/* <h1>{ title }</h1> */}
              <CalificationSection stars={stars} amountReviews={amountReviews} />
            </div>
            {
              containsTikal ? <TikalForm /> : ''
            }
          </div>
          <hr />
            <div className="tour-features">
              <img src="../assets/reloj.png" alt="time" className="calification-star" />
              <span> { time } horas |&ensp;</span><br/>
              <img src="../assets/chat.png" alt="language" className="calification-star" />
              <span> Español o Inglés |&ensp;</span><br/>
              <img src="../assets/bus.png" alt="vehicle" className={`calification-star ${showTransport}`} />
              <span className={showTransport}> Transporte de ida y vuelta &ensp;</span>
            </div>
          <hr />
          <div className="overview">
            <h3>Descripción del Tour</h3>
            <p>{ description }</p>
            <p>Los tours pueden variar según el paquete que escoja. Pregunte a través de WhatsApp para información de tours más completos o la diferencia entre paquetes.</p>
          </div>
          <hr />
          <div className="highlights">
            <h3>Destacados del Tour</h3>
            <p>Dentro de los lugares más importantes a visitar se encuentran:</p>
            <ul>
              {
                highlights.map( (element, i) => {
                  return (
                    <li key={`highlight-${i}`}>
                      {element}
                    </li>
                  );
                })
              }
            </ul>
          </div>
          <hr />
          <div className="schedules">
            <h3>Horarios</h3>
            <ul className='schedules-list'>
              {
                schedules.map( (element, i) => {
                  return (
                    <li key={`schedule-${i}`}>
                      {element}
                    </li>
                  );
                })
              }
            </ul>
          </div>
            {/* <path fill-rule="evenodd" clip-rule="evenodd" d="M17.85 4.15c.2.2.2.5 0 .7l-10.5 10.5a.5.5 0 01-.72-.02l-4-4.5a.5.5 0 01.74-.66l3.65 4.1L17.15 4.15c.2-.2.5-.2.7 0z"></path> */}
          <hr />
          <PriceTableComponent preciosPorPersona={preciosPorPersona} />
          <IncludeComponent included={included} notIncluded={notIncluded} />
          <span>{ transportAditional }</span>
          <hr />
          <div className="tour-pickup">
            <h3>Punto de Encuentro</h3>
            {/* <span>El servicio de transporte de ida y vuelta parte y regresa a su hotel</span> */}
            {/* <span>El transporte sale de la zona 10 de Ciudad de Guatemala, si se encuentra hospedado en esta zona se le puede recoger en su hotel</span> */}
            <span>{ departurePoint.place } | { departurePoint.direction }</span>
            <iframe title='departurePoint' className='map-site' src={ departurePoint.urlLocation } allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
            <h3>Preguntas frecuentes</h3>
            {
              FAQ.map( (question, i) => {
                return (
                  <div className="question" key={ `question-${i}` }>
                    <span><b>{ question.question }</b></span>
                    <div className="answer">
                    <span>{ question.answer }</span>
                    </div>
                  </div>
                );
              })
            }
          <hr />
            <h3>Fotos de turistas</h3>
            <div className="img-gallery">
              {
                travelerPhotos.map( (photo, i) => {
                  return (
                    <img src={photo} alt="Traveler" key={ `traveler-img-${i}` } />
                  );
                })
              }
            </div>
          <hr />
            <h3>Reviews</h3>
            {
              reviews.map( (review, index) => {
                return (
                  <div className="review" key={ `review-${index}` } id={ `review-${index}` }>
                    <CalificationSection stars={review.stars} />
                    <h5>{ review.title }</h5>
                    <span>{ review.name } | { review.date }</span>
                    <span><p>{ review.review }</p></span>
                  </div>
                );
              })
            }
            <hr />
            <div className='cancellation-policy'>
              <h3>Política de Cancelación</h3>
              <span>{ cancellationPolicy }</span>
            </div>
            <p><Link className='btn-link' to="../travel-page">Home</Link> | <span className='btn-link' onClick={() => navigate(-1)}>Regresar a Tours</span></p>
        </div>
        <ContactComponent />
        <ReservarComponent handleClick={ handleShow } text={ reserve } />    
        <FooterComponent />
    </>
  )
}
